import asdaexpress from "./logos/asdaexpress.png";
import beefeater from "./logos/beefeater.png"
import brewersfayre from "./logos/brewersfayre.png";
import bp from "./logos/bp.png";
import budgens from "./logos/budgens.png";
import burgerking from "./logos/burgerking.png";
import cocodimama from "./logos/cocodimama.png";
import chozennoodle from "./logos/chozennoodle.png";
import cornishbakery from "./logos/cornishbakery.png";
import costa from "./logos/costa.png";
import coop from "./logos/coop.png"
import costcutter from "./logos/costcutter.png"
import costaexpress from "./logos/costaexpress.png";
import chopstix from "./logos/chopstix.png";
import chowasiankitchen from "./logos/chowasiankitchen.png";
// import deepblue from "./logos/deepblue.png";
import dominos from "./logos/dominos.png";
import dunkindonuts from "./logos/dunkindonuts.png";
import elmexicana from "./logos/elmexicana.png";
import esso from "./logos/esso.png";
import extra from "./logos/extra.png";
import extrasquare from "./logos/extrasquare.png";
// import freshfoodcafe from "./logos/freshfoodcafe.png";
import gardensquaredeli from "./logos/gardensquaredeli.png";
// import germandonerkebab from "./logos/germandonerkebab.png";
import greggs from "./logos/greggs.png";
import gulf from "./logos/gulf.png";
import harvester from "./logos/harvester.png";
// import jdwetherspoon from "./logos/jdwetherspoon.png";
import jet from "./logos/jet.png";
import krispykreme from "./logos/krispykreme.png";
import kfc from "./logos/kfc.png";
import leon from "./logos/leon.png";
import londis from "./logos/londis.png";
import mands from "./logos/mands.png";
import mcdonalds from "./logos/mcdonalds.png";
import morrisonsdaily from "./logos/morrisonsdaily.png";
import motoway from "./logos/motoway.png";
import motowayround from "./logos/motoround.png";
import nandos from "./logos/nandos.png";
import nisalocal from "./logos/nisalocal.png";
import okdiner from "./logos/okdiner.png";
import pizzaexpress from "./logos/pizzaexpress.png";
import pizzahut from "./logos/pizzahut.png";
import phatpasty from "./logos/phatpasty.png";
import pret from "./logos/pret.png";
import roadchef from "./logos/roadchef.png";
import roadchefround from "./logos/roadchefround.png";
import shell from "./logos/shell.png"
import starbucks from "./logos/starbucks.png";
import spar from "./logos/spar.png";
import subway from "./logos/subway.png";
import tacobell from "./logos/tacobell.png";
// import tapori from "./logos/tapori.png";
import texaco from "./logos/texaco.png";
import tortilla from "./logos/tortilla.png";
import totalenergies from "./logos/totalenergies.png";
import thegoodbreakfast from "./logos/thegoodbreakfast.png";
import waitrose from "./logos/waitrose.png";
import warrensbakery from "./logos/warrensbakery.png";
import welcomebreak from "./logos/welcomebreak.png";
import welcomebreakround from "./logos/welcomebreakround.png";
import westcornwallpastyco from "./logos/westcornwallpastyco.png";
import wildbeancafe from "./logos/wildbeancafe.png";
import whsmith from "./logos/whsmith.png";

const restaurantLogosMap = {
  "Burger King": burgerking,
  "Chopstix Noodle Bar": chopstix,
  "Chow Asian Kitchen": chowasiankitchen,
  "Chozen Noodle": chozennoodle,
  "Coco di Mama": cocodimama,
  "Costa Coffee": costa,
  "Costa Express": costaexpress,
  // "Deep Blue Fish & Chips": deepblue, \\ not in the new data
  "Domino's": dominos,
  "Dunkin' Donuts": dunkindonuts,
  "El Mexicana": elmexicana,
  // "Fresh Food Cafe": freshfoodcafe, \\ not in the new data
  "Garden Square Deli": gardensquaredeli,
  // "German Doner Kebab": germandonerkebab, \\ not in the new data
  Greggs: greggs,
  // "JD Wetherspoon": jdwetherspoon, \\ not in the new data
  KFC: kfc,
  "Krispy Kreme": krispykreme,
  LEON: leon,
  McDonalds: mcdonalds,
  Nandos: nandos,
  "OK Diner": okdiner,
  "Pizza Express": pizzaexpress,
  "Pizza Hut": pizzahut,
  "The Phat Pasty Co.": phatpasty,
  "Pret A Manger": pret,
  Starbucks: starbucks,
  Subway: subway,
  "Taco Bell": tacobell,
  // "Tapori Indian Street Food": tapori, \\ here https://motorwayservices.uk/Cobham but not a <a> tag
  // "The Breakfast Kitchen", // leaving as this is RC own brand - no logo
  "The Cornish Bakery": cornishbakery,
  "The Good Breakfast": thegoodbreakfast,
  //  'The Pasty Shop', \\ not in the new data
  Tortilla: tortilla,
  "Warrens Bakery": warrensbakery,
  "West Cornwall Pasty": westcornwallpastyco,
  "Wild Bean Café": wildbeancafe,

  // NEED ADDING
  "Amesbury Archer Harvester": harvester,
  "The Broxden Manor Harvester": harvester,
  "Aire & Calder Brewers Fayre": brewersfayre,
  "Brampton Hut Brewers Fayre": brewersfayre,
  "Cepen Park Brewers Fayre": brewersfayre,
  "Duke of York Brewers Fayre": brewersfayre,
  "Flagstaff Island Brewers Fayre": brewersfayre,
  "Kincardine Way Brewers Fayre": brewersfayre,
  "Loggans Moor Brewers Fayre": brewersfayre,
  "Monkton Lodge Brewers Fayre": brewersfayre,
  "Mulberry Tree Brewers Fayre": brewersfayre,
  "Queens Crossing Brewers Fayre": brewersfayre,
  "Frome Flyer Beefeater": beefeater,
  "Great Oak Beefeater": beefeater,
  "Moor Farm Beefeater": beefeater,
  "The Applecart Beefeater": beefeater,
  "The Orchard Beefeater": beefeater
};

const shopsLogosMap = {
  "ASDA Express": asdaexpress,
  Budgens: budgens,
  "Co-op Food": coop,
  "Costcutter": costcutter,
  Londis: londis,
  "M&S": mands,
  "Morrisons Daily": morrisonsdaily,
  "Nisa Local": nisalocal,
  SPAR: spar,
  WHSmith: whsmith,
  "Waitrose & Partners": waitrose,
  // NEED ADDING
  // "Mace": "Mace",
};

const fuelLogosMap = {
  "BP": bp,
  "Esso": esso,
  "Gulf": gulf,
  "JET": jet,
  // "Murco": Murco,
  "Shell": shell,
  "Texaco": texaco,
  "TotalEnergies": totalenergies
};

const stationsLogoMap = {
  Extra: extra,
  "Moto": motoway,
  Roadchef: roadchef,
  "Welcome Break": welcomebreak,
};

const stationsSmallLogoMap = {
  Extra: extrasquare,
  "Moto": motowayround,
  Roadchef: roadchefround,
  "Welcome Break": welcomebreakround,
};

export { restaurantLogosMap, shopsLogosMap, fuelLogosMap, stationsLogoMap, stationsSmallLogoMap };
