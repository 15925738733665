
export default function SearchAreaButton({ submitBboxFilters }) {

    return (
        <div className="search-area-button-div">
            <button 
              className="search-area-button" 
              id="search-area-button"
              onClick={submitBboxFilters}
            >
                Search this area
            </button>
        </div>
    )
}
