import { Marker } from "react-leaflet";
import L from "leaflet";

import locationmarker from "../constants/icons/location-marker.png";

function CurrentLocationArrow({ currentLatLon }) {
  const locationIcon = new L.Icon({
    iconUrl: locationmarker,
    iconSize: [20, 20],
  });

  return (
    <div>
      {currentLatLon.length === 2 && (
        <Marker icon={locationIcon} position={currentLatLon} />
      )}
    </div>
  );
}

export default CurrentLocationArrow;
