import "./App.css";
import { useState, useEffect, useRef } from "react";

import BaseMap from "./components/map";
import SearchBox from "./components/searchBox";
import AddFiltersButton from "./components/addFiltersButton";
import AdBanner from "./components/adBanner";
import OffcanvasMenu from "./components/offCanvasMenu";
import FeedbackModal from "./components/feedbackForm";
import SearchAreaButton from "./components/searchAreaButton";

import stationSample from "./constants/station_sample.json";

function App() {
  const [routeStart, setRouteStart] = useState("");
  const [routeEnd, setRouteEnd] = useState("");
  const [stations, setStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentMapBbox, setCurrentMapBbox] = useState([]);         // not used
  const [currentBboxFilters, setCurrentBboxFilters] = useState([]); // not used
  const hideSearchAreaButton = useRef(true);                        // not used

  const handleFeedbackShow = () => setShowFeedback(true);
  const handleFeedbackClose = () => setShowFeedback(false);

  const defaultZoomLevel = 10;

  // Set stations data once when the component mounts
  useEffect(() => {
    setStations(stationSample);
    setSelectedFilters(["All Locations"]);
  }, []);

  // set current bounding box filters
  function submitBboxFilters() {
    setCurrentBboxFilters(currentMapBbox);
  }

  useEffect(() => {
    const filterStations = async () => {
      const filters = selectedFilters.filter(
        (item) => item !== "All Locations"
      );

      const keptStations = [];
      for (let i = 0; i < stations.length; i++) {
        
        let inFilters = false;
        if (filters.length > 0) {
          for (let j = 0; j < selectedFilters.length; j++) {
            if (
              stations[i].restaurants.includes(filters[j]) |
              stations[i].provider.includes(filters[j])
            ) {
              inFilters = true;
            }
          }
        } else {
          inFilters = true;
        }

        let inBbox = false;
        if (
          stations[i].latitude > currentMapBbox["_southWest"]["lat"] &&
          stations[i].latitude < currentMapBbox["_northEast"]["lat"] &&
          stations[i].longitude > currentMapBbox["_southWest"]["lng"] &&
          stations[i].longitude < currentMapBbox["_northEast"]["lng"]
        ) {
          inBbox = true;
        } 

        if (inFilters && inBbox) {
          keptStations.push(stations[i]);
        }
      }

      console.log("Clicked!", keptStations);

      setFilteredStations(keptStations);
      document.getElementById("search-area-button").style.display = "none";
    };

    if (JSON.stringify(currentBboxFilters) !== JSON.stringify([])) {
      filterStations();
    }
  }, [selectedFilters, stations, currentBboxFilters]);

  // show search area button again when the map bounding box changes
  useEffect(() => {
    function toggleHidingSearchButton() {
      if (hideSearchAreaButton.current) {
        document.getElementById("search-area-button").style.display = "none";
        hideSearchAreaButton.current = false;
      } else {
        document.getElementById("search-area-button").style.display = "block";
      }
    }

    if (currentMapBbox.length === 0) return;
    toggleHidingSearchButton();
  }, [currentMapBbox]);

  return (
    <div>
      <BaseMap
        position={[55, -2.0]}
        zoom={6}
        stations={filteredStations}
        routeStart={routeStart}
        routeEnd={routeEnd}
        setCurrentMapBbox={setCurrentMapBbox}
        setCurrentBboxFilters={setCurrentBboxFilters}
        hideSearchAreaButton={hideSearchAreaButton}
        defaultZoomLevel={defaultZoomLevel}
      />
      <SearchAreaButton submitBboxFilters={submitBboxFilters}/>
      <SearchBox setRouteStart={setRouteStart} setRouteEnd={setRouteEnd} />
      <AddFiltersButton setShowOffCanvas={setShowOffCanvas} />
      <OffcanvasMenu
        showOffCanvas={showOffCanvas}
        setShowOffCanvas={setShowOffCanvas}
        handleFeedbackShow={handleFeedbackShow}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <FeedbackModal show={showFeedback} handleClose={handleFeedbackClose} />
      <AdBanner />
    </div>
  );
}

export default App;
