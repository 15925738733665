import { Offcanvas, Button } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';

import FeedbackButton from "../components/feedbackButton";
import StripeButton from "../components/stripeButton";
import menubutton from "../constants/icons/menu-button.png";


function OffcanvasMenu({ showOffCanvas, setShowOffCanvas, handleFeedbackShow, selectedFilters, setSelectedFilters }) {

  const handleClose = () => setShowOffCanvas(false);
  const handleShow = () => setShowOffCanvas(true);

  const filterOptions = [
    "All Locations",
    "Extra",
    "Moto",
    "Roadchef",
    "Welcome Break",
    "Burger King",
    // "Chopstix Noodle Bar",
    // "Chow Asian Kitchen",
    // "Chozen Noodle",
    // "Coco di Mama",
    "Costa Coffee",
    "Greggs",
    "KFC",
    "McDonalds",
    "Nandos",
    "Pizza Express",
    "Pret A Manger",
    "Starbucks",
    "Subway",
    "Taco Bell",
    // "Tortilla",
    // "West Cornwall Pasty"
  ];

  const toggleSelection = (item) => {
    setSelectedFilters((prevSelectedFilters) => {
      // Toggle the item
      let newFilters = prevSelectedFilters.includes(item)
        ? prevSelectedFilters.filter((i) => i !== item) // Remove item if already selected
        : [...prevSelectedFilters, item]; // Add item if not selected

      if ( 
        ( item === "All Locations" ) & ( newFilters.includes("All Locations") )
      ) {
        return ["All Locations"];
      } else if (
        ( item === "All Locations" ) & ( !newFilters.includes("All Locations") & ( newFilters.length === 0 ) )
      ) {
        return ["All Locations"];
      } else if (
        ( newFilters.length === 0 ) 
      ) {
        return ["All Locations"];
      } else {
        return newFilters.filter((i) => i !== "All Locations")
      }
    });
  };

  return (
    <div>
      <Button className="off-canvas-button" onClick={handleShow}>
        <img src={menubutton} className="off-canvas-button-image" alt="menu-button"/>
      </Button>
      <Offcanvas show={showOffCanvas} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>ServiceMe</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="button-list">

        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              Filters
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup as="ol">
                {filterOptions.map((item) => (
                  <ListGroup.Item 
                    action
                    key={item}
                    active={selectedFilters.includes(item)}
                    onClick={() => toggleSelection(item)}
                    className="filters-option"
                    as="li">
                      {item}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          <FeedbackButton handleFeedbackShow={handleFeedbackShow} />
          <StripeButton />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default OffcanvasMenu;
