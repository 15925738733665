import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

import { logger } from "../logger.js";

const FeedbackForm = ({ show, handleClose }) => {
  const [feedback, setFeedback] = useState("");

  const handleSubmit = () => {
    // Handle form submission here (e.g., send feedback to server)
    logger({ logID: "FeedbackForm", text: feedback });
    // Reset feedback state
    setFeedback("");
    // Close modal
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Please enter your feedback here..."
          rows="10"
          style={{ width: "100%" }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackForm;
